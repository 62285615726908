var sw = window.innerWidth;
var sh = window.innerHeight;
var header = document.querySelector("header");
var sticky = 0;


// Font Resizer-------------------Start
function fontResizer() {
    var perc = parseInt(sw) / 118.9375;
    document.body.style.fontSize = perc + 'px';
}


// Loader-------------------Start
function pageLoader() {
    // document.querySelector("html").classList.add("loadjs");
    setTimeout(function () {
        document.querySelector(".loader-first").style.display = 'none';
        document.querySelector("html").classList.remove("loadjs");
    }, 300);
}


// Navigation slide Active on a mobile 
let toggleMenu = document.querySelector('#toggle');
let htmlEl = document.querySelector("html");

toggleMenu.addEventListener("click", (event) => {
    let _this = event.target;
    _this.classList.toggle('active');
    document.querySelector('.navigation').classList.toggle('open')
    // htmlEl.classList.toggle("overflow-hidden");
})

let menuitem = document.querySelectorAll(".menu__menuitem--menulink");

// menuitem.addEventListener("click", function () {
//     document.querySelector('.navigation').classList.remove('open')
//     document.querySelector('.nav-button').classList.remove('active')
// });

for (let i = 0; i < menuitem.length; i++) {
    menuitem[i].addEventListener("click", function () {
        document.querySelector('.navigation').classList.remove('open')
        document.querySelector('.nav-button').classList.remove('active')
    });
}

// accordion


let allAccord = document.body.querySelectorAll(".faqs__faq-accordion");

if (allAccord.length > 0) {
    for (let i = 0; i < allAccord.length; i++) {
        const setActive = (el) => {

            [...el.parentElement.parentElement.children].forEach((sib) => {

                let panel = sib.children[1];
                panel.style.maxHeight = null;
                [...sib.children].forEach((a) => {
                    console.log("=======", a.classList.remove("opened"));
                })
            });
            el.classList.add("opened");
            let panel = el.nextElementSibling;
            panel.style.maxHeight = panel.scrollHeight + "px";
        };
        let spans = [...allAccord[i].querySelectorAll(".faqs__faq-accordion > h5")];
        spans.forEach((el) => el.addEventListener("click", (e) => setActive(el)));
    }
}

if (allAccord && allAccord.length) {
    let mainacc = document.querySelectorAll(".faqs__faq-accordion > h5");
    mainacc[0].classList.add("opened");
    mainacc[0].nextElementSibling.style.maxHeight = mainacc[0].nextElementSibling.scrollHeight + "px";
}

// Landscape Mode off 
function landscapeModeOff() {
    if (sh < 450 && sw > 480 && sw < 820) {
        document.getElementById("portrait-warnning").style.display = "flex";
    } else {
        document.getElementById("portrait-warnning").style.display = "none";
    }
}


// Sticky Header----------------Start
function stickyHeader() {
    if (window.pageYOffset > sticky) {
        header.classList.add("sticky");
    } else {
        header.classList.remove("sticky");
    }
}


// SVG Convert Code
let svgs = document.querySelectorAll('img.svg-convert');
svgs.forEach((el) => {
    const imgID = el.getAttribute('id');
    const imgClass = el.getAttribute('class');
    const imgURL = el.getAttribute('src');

    fetch(imgURL)
        .then((response) => response.text())
        .then(data => {
            const parser = new DOMParser();
            const xmlDoc = parser.parseFromString(data, 'text/html');
            let svg = xmlDoc.querySelector('svg');
            if (typeof imgID !== 'undefined') {
                svg.setAttribute('id', imgID);
            }

            if (typeof imgClass !== 'undefined') {
                svg.setAttribute('class', imgClass + ' svg-converted');
            }

            svg.removeAttribute('xmlns:a');

            el.parentNode.replaceChild(svg, el);
        })
});

// Window on Load-----------------Start
window.onload = function () {
    pageLoader();
    if (sw > 1199) {
        fontResizer();
    }
};

// Window On Resize-----------------Start
window.addEventListener('resize', (event) => {
    sw = window.innerWidth;
    sh = window.innerHeight;
    landscapeModeOff();
    if (sw > 1025) {
        if (sw < 1400 && sw > 1300 && sh > 900) {} else {
            fontResizer();
        }
    }
});


// Window On Scroll-----------------Start
window.addEventListener('scroll', (event) => {
    stickyHeader();
});



if (document.querySelector(".join-sephora__imageSlider")) {
    var joinSephora = new Swiper(".join-sephora__imageSlider", {
        loop: true,
        freeMode: true,
        spaceBetween: 0,
        grabCursor: true,
        slidesPerView: "auto",
        allowTouchMove: false,
        loop: true,
        autoplay: {
            delay: 1,
            disableOnInteraction: true
        },
        freeMode: true,
        speed: 5000,
        freeModeMomentum: false
    });
}

if (document.querySelector(".beauty-influencer__bottom-tagSlider")) {
    var tagSlider = new Swiper(".beauty-influencer__bottom-tagSlider", {
        loop: true,
        freeMode: true,
        spaceBetween: 0,
        grabCursor: true,
        slidesPerView: "auto",
        allowTouchMove: false,
        loop: true,
        autoplay: {
            delay: 1,
            disableOnInteraction: true
        },
        freeMode: true,
        speed: 5000,
        freeModeMomentum: false
    });
}
if (document.querySelector(".personalized-perks__eventSlider")) {
    var eventSlider = new Swiper(".personalized-perks__eventSlider", {
        slidesPerView: 1,
        speed: 3000,
        spaceBetween: 10,
        autoplay: {
            delay: 1,
            disableOnInteraction: true
        },
        scrollbar: {
            el: ".swiper-scrollbar",
        },
        breakpoints: {
            // when window width is >= 640px
            768: {
                slidesPerView: 1.3,
            }
        }
    });
}

// if (sw < 1200) {
//     if (document.querySelector(".how-works__cardSlider")) {
//         var cardSlider = new Swiper(".how-works__cardSlider", {
//             slidesPerView: 1,
//             speed: 5000,
//             spaceBetween: 0,
//             autoplay: {
//                 delay: 1,
//                 disableOnInteraction: true
//             },
//             breakpoints: {
//                 // when window width is >= 640px
//                 992: {
//                     slidesPerView: 2,
//                 }
//             }
//         });
//     }
// }

if ($(document).find("div").hasClass("video-wrapper")) {
    setTimeout(function () {
        $(".iframeData").html('<iframe src="' + $('.iframeData').attr('data-src') + '" allow="autoplay" allowfullscreen frameborder="0"></iframe>');
    }, 20);
}

// let allAccord = document.body.querySelectorAll(".menu");

// if (allAccord.length > 0) {
//     for (let i = 0; i < allAccord.length; i++) {
//         const setActive = (el) => {
//             [...el.parentElement.parentElement.children].forEach((sib) => {
//                 sib.children[i].classList.remove("active");
//             });
//             el.classList.add("active");
//         };
//         let spans = [...allAccord[i].querySelectorAll(".menu__menuitem--menulink")];
//         spans.forEach((el) => el.addEventListener("click", (e) => setActive(el)));
//     }
// }

let card1 = document.getElementById("card1");
let card2 = document.getElementById("card2");
let card3 = document.getElementById("card3");
let card4 = document.getElementById("card4");

$('.individual-card').click(function (e) {
    $('.individual-card').removeClass('active');
    let elemprev = $(this).parent().prevAll().find('.individual-card');
    let elemnext = $(this).parent().nextAll().find('.individual-card');
    elemprev.addClass('viewed');
    elemnext.removeClass('viewed');
    if (elemprev[0]) elemprev[0].style.zIndex = "0";
    if (elemprev[1]) elemprev[1].style.zIndex = "1";
    if (elemprev[2]) elemprev[2].style.zIndex = "2";

    if (elemnext[0]) elemnext[0].style.zIndex = "2";
    if (elemnext[1]) elemnext[1].style.zIndex = "1";
    if (elemnext[2]) elemnext[2].style.zIndex = "0";
    $(this).addClass('active').removeClass('viewed');
})

// card1.addEventListener("click", (e) => {
//     card1.classList.add('active').classList.remove('viewed');
//     card2.classList.remove('active').classList.remove('viewed');
//     card3.classList.remove('active').classList.remove('viewed');
//     card4.classList.remove('active').classList.remove('viewed');
//     card4.style.zIndex = "0";
//     card3.style.zIndex = "1";
//     card2.style.zIndex = "2";
// });
// card2.addEventListener("click", (e) => {
//     card2.classList.add('active').classList.remove('viewed');
//     card1.classList.remove('active').classList.add('viewed');
//     card3.classList.remove('active').classList.remove('viewed');
//     card4.classList.remove('active').classList.remove('viewed');

//     card4.style.zIndex = "0";
//     card3.style.zIndex = "1";
//     card1.style.zIndex = "2";
// });
// card3.addEventListener("click", (e) => {
//     card3.classList.add('active')
//     .classList.remove('viewed');
//     card1.classList.remove('active').classList.add('viewed');
//     card2.classList.remove('active').classList.add('viewed');
//     card4.classList.remove('active').classList.remove('viewed');
//     card4.style.zIndex = "0";
//     card1.style.zIndex = "1";
//     card2.style.zIndex = "2";
// });
// card4.addEventListener("click", (e) => {
//     card4.classList.add('active').classList.remove('viewed');
//     card1.classList.remove('active').classList.add('viewed');
//     card3.classList.remove('active').classList.add('viewed');
//     card2.classList.remove('active').classList.add('viewed');
//     card1.style.zIndex = "0";
//     card2.style.zIndex = "1";
//     card3.style.zIndex = "2";
// });


let innerLink = document.querySelectorAll('.js-inner')
innerLink.forEach(el => el.addEventListener('click', e => functionforscroll(e.target.getAttribute('data-id'))))

function functionforscroll(id) {
    var getMeTo = document.getElementById(id);
    const yOffset = -20;
    const y = getMeTo.getBoundingClientRect().top + window.pageYOffset + yOffset;
    setTimeout(function () {
        window.scrollTo({
            top: y,
            behavior: 'smooth'
        });
        // getMeTo.scrollIntoView();
    }, 500)
    var uri = window.location.toString();
    if (uri.indexOf("#") > 0) {
        var clean_uri = uri.substring(0, uri.indexOf("#"));
        window.history.replaceState({}, document.title, clean_uri);
    }
}

if (sw < 480) {
    function functionforscroll(id) {
        var getMeTo = document.getElementById(id);
        const yOffset = -80;
        const y = getMeTo.getBoundingClientRect().top + window.pageYOffset + yOffset;
        setTimeout(function () {
            window.scrollTo({
                top: y,
                behavior: 'smooth'
            });
            // getMeTo.scrollIntoView();
        }, 500)
        var uri = window.location.toString();
        if (uri.indexOf("#") > 0) {
            var clean_uri = uri.substring(0, uri.indexOf("#"));
            window.history.replaceState({}, document.title, clean_uri);
        }
    }
}


// Get all sections that have an ID defined
const sections = document.querySelectorAll("section[id]");

// Add an event listener listening for scroll
window.addEventListener("scroll", navHighlighter);

function navHighlighter() {

    // Get current scroll position
    let scrollY = window.pageYOffset;

    // Now we loop through sections to get height, top and ID values for each
    sections.forEach(current => {
        const sectionHeight = current.offsetHeight;
        const sectionTop = current.offsetTop - 50;
        sectionId = current.getAttribute("id");

        /*
        - If our current scroll position enters the space where current section on screen is, add .active class to corresponding navigation link, else remove it
        - To know which link needs an active class, we use sectionId variable we are getting while looping through sections as an selector
        */
        if (
            scrollY > sectionTop &&
            scrollY <= sectionTop + sectionHeight
        ) {
            document.querySelector(".navigation a[href*=" + sectionId + "]").classList.add("active");
        } else {
            document.querySelector(".navigation a[href*=" + sectionId + "]").classList.remove("active");
        }
    });
}